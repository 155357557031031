<template>
    <div class="flex flex-col">
        <div
            v-if="isSelectedDocument"
            class="absolute left-0 right-0 h-10 bg-gray-50"
        />
        <div class="z-10 flex h-10 items-center">
            <div
                class="flex cursor-pointer items-center gap-2.5"
                @click="toggleShowChildren"
            >
                <div class="text-gray-900" v-if="hasChildren">
                    <base-icon
                        name="line-icons:arrows:chevron-down"
                        variant="inherit"
                    />
                </div>

                <div v-else class="min-h-5 min-w-5" />

                <base-icon name="line-icons:files:folder" variant="primary" />

                <span
                    class="whitespace-nowrap text-sm font-normal text-gray-700"
                >
                    {{ document.full_name }}
                </span>
            </div>
        </div>

        <template v-if="hasChildren">
            <accountant-clients-documents-folders
                ref="refFolders"
                v-show="state.showChildren"
                v-for="child in document.children"
                :key="child.uuid"
                :document="child"
                class="ml-6"
            />
        </template>
    </div>
</template>

<script setup>
import { toRefs } from 'vue'

const props = defineProps({
    document: {
        type: Object,
        required: true,
    },
})

const route = useRoute()
const router = useRouter()

const refFolders = ref(null)

const state = reactive({
    showChildren: false,
})

const hasChildren = computed(() => {
    const { document } = props

    return document.children && document.children.length > 0
})

const isSelectedDocument = computed(
    () => props.document.uuid === route.query.id
)

onMounted(() => {
    checkIfChildrenOpening()
})

const checkIfChildrenOpening = () => {
    if (!refFolders.value) {
        return
    }

    state.showChildren = refFolders.value.some(
        (i) => i.isSelectedDocument || i.showChildren
    )
}

const toggleShowChildren = () => {
    router.replace({
        query: {
            id: props.document.uuid,
        },
    })

    if (!hasChildren.value) {
        return
    }

    state.showChildren = !state.showChildren
}

defineExpose({
    isSelectedDocument,
    ...toRefs(state),
})
</script>
